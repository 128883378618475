import parse from 'html-react-parser';
import { useRouter } from 'next/dist/client/router';
import React, { useEffect } from 'react';

import {
    ArticleContactForm,
    ArticleNumberTextTeaser,
    ArticlePageBookmark,
    ArticlePageButton,
    ArticlePageContentTeaser as ArticlePageContentTeaserType,
    ArticlePageContentTeaserSmall,
    ArticlePageData,
    ArticlePageFaqSection,
    ArticlePageFlexContainer,
    ArticlePageHeadImageSection,
    ArticlePageHeadImageWithTextSection,
    ArticlePageImageRoot,
    ArticlePageNewsletter as ArticlePageNewsletterType,
    ArticlePageParagraph,
    ArticlePagePodcastTeaser,
    ArticlePageProductTeaser as ArticlePageProductTeaserType,
    ArticlePageQuoteSection,
    ArticlePageRegisterFormLight,
    ArticlePageSpacer,
    ArticlePageTopline,
    ArticlePageVariantsInstancesTeaser as ArticlePageVariantsInstancesTeaserType,
    ArticlePageVideo,
    HorizontalAlignment,
    useArticlePage,
    VerticalAlignment
} from '../../api/hooks/useArticlePage.hook';
import { StrapiFaqContainer } from '../../api/models/strapi/strapi-faq-container';
import { StrapiHeadline } from '../../api/models/strapi/strapi-headline';
import { LogoText } from '../../assets/logo-text.svg';
import BasicCard from '../../components/_shared/basic-card/basic-card';
import { Button } from '../../components/_shared/button/button';
import ContactForm from '../../components/_shared/contact-form/contact-form';
import HeadImageSection from '../../components/_shared/head-image-section/head-image-section';
import HeadImageWithTextSection
    from '../../components/_shared/head-image-with-text-section/head-image-with-text-section';
import { DefaultHeader } from '../../components/_shared/header/default-header/default-header';
import { HeaderFooterWrapper } from '../../components/_shared/header-footer-wrapper/header-footer-wrapper';
import { Media } from '../../components/_shared/media/media';
import { MetaTagsResolver } from '../../components/_shared/meta-tags-resolver/meta-tags-resolver';
import NumberTextTeaser from '../../components/_shared/number-text-teaser/number-text-teaser';
import PodcastTeaserCard from '../../components/_shared/podcast-teaser-card/podcast-teaser-card';
import QuoteSection from '../../components/_shared/quote-section/quote-section';
import { Spacer } from '../../components/_shared/spacer/spacer';
import { Stage } from '../../components/_shared/stage/stage';
import TextImageTeaser from '../../components/_shared/text-image-teaser/text-image-teaser';
import TextOnlyTeaser from '../../components/_shared/text-only-teaser/text-only-teaser';
import { Typography } from '../../components/_shared/typography/typography';
import VideoComponent from '../../components/_shared/video-component/video-component';
import {
    ArticlePageBookmark as ArticlePageBookmarkComp
} from '../../components/article-page/article-page-bookmark/article-page-bookmark';
import { ArticlePageFaqBox } from '../../components/article-page/article-page-faq-box/article-page-faq-box';
import ArticlePageNewsletter from '../../components/article-page/article-page-newsletter/article-page-newsletter';
import {
    ArticlePageProductTeaser
} from '../../components/article-page/article-page-product-teaser/article-page-product-teaser';
import { ArticlePageTempHeader } from '../../components/article-page/article-page-temp-header/article-page-temp-header';
import {
    ArticlePageVariantsInstancesTeaser
} from '../../components/article-page/article-page-variants-instances-teaser/article-page-variants-instances-teaser';
import { RegisterFormLight } from '../../components/register/register-form/register-form-light';
import MyApp from '../../pages/_app';
import { ArticlePageTypes } from '../../utils/_enums/article-page-types';
import { BasicCardVariants } from '../../utils/_enums/basic-card-variants.enum';
import { ButtonVariantsEnum } from '../../utils/_enums/button-variants.enum';
import { HorizontalPosition } from '../../utils/_enums/horizontal-position.enum';
import { MediaContentType } from '../../utils/_enums/image-content-type.enum';
import { TextOnlyTeaserSizes } from '../../utils/_enums/text-only-teaser-sizes.enum';
import { TypographyVariantsEnum } from '../../utils/_enums/typeography-variants.enum';
import { EnumToLanguageMapper } from '../../utils/_helper/enum-to-language-mapper';
import { mapStrapiHeadline } from '../../utils/_helper/strapi-mapper';
import styles from './article-page.module.scss';

const resolveElementForData = (
    {
        __component,
        ...props
    }:
        | (StrapiHeadline & { __component: 'atoms.headline' })
        | ArticlePageTopline
        | ArticlePageParagraph
        | ArticlePageContentTeaserType
        | ArticlePageProductTeaserType
        | ArticlePageImageRoot
        | ArticlePageContentTeaserSmall
        | ArticleNumberTextTeaser
        | ArticlePageFaqSection
        | ArticlePageFlexContainer
        | ArticlePagePodcastTeaser
        | ArticlePageVariantsInstancesTeaserType
        | ArticlePageQuoteSection
        | ArticlePageHeadImageSection
        | ArticleContactForm
        | ArticlePageHeadImageWithTextSection
        | ArticlePageNewsletterType
        | ArticlePageButton
        | ArticlePageRegisterFormLight
        | ArticlePageSpacer
        | ArticlePageVideo
        | ArticlePageBookmark
        | (StrapiFaqContainer & { __component: 'molecules.faq-section' }),
    elements: (
        | (StrapiHeadline & { __component: 'atoms.headline' })
        | ArticlePageTopline
        | ArticlePageParagraph
        | ArticlePageContentTeaserType
        | ArticlePageImageRoot
        | ArticlePageContentTeaserSmall
        | ArticlePageFlexContainer
        | ArticleNumberTextTeaser
        | ArticlePageNewsletterType
        | ArticlePagePodcastTeaser
        | ArticlePageVariantsInstancesTeaserType
        | ArticleContactForm
        | ArticlePageQuoteSection
        | ArticlePageHeadImageSection
        | ArticlePageSpacer
        | ArticlePageVideo
        | ArticlePageHeadImageWithTextSection
        | ArticlePageRegisterFormLight
        | ArticlePageBookmark
        | (StrapiFaqContainer & { __component: 'molecules.faq-section' })
        )[],
    index: number,
    inverted: boolean
) => {
    switch (__component) {
        case 'atoms.headline': {
            return (
                <Typography
                    className={(props as StrapiHeadline)?.cssClass ?? ''}
                    element={(props as StrapiHeadline)?.element}
                    inverted={inverted}
                    variant={mapStrapiHeadline(
                        (props as StrapiHeadline)?.style
                    )}
                    style={{
                        textAlign:
                            ((props as StrapiHeadline)?.textAlign?.toLowerCase?.() as
                                | 'left'
                                | 'right'
                                | 'center'
                                | 'justify'
                                | undefined) ?? 'left'
                    }}
                >
                    {(props as StrapiHeadline)?.headlineContent}
                </Typography>
            );
        }
        case 'atoms.paragraph': {
            return (
                <Typography
                    className={(props as ArticlePageParagraph)?.cssClass}
                    inverted={inverted}
                    variant={TypographyVariantsEnum.PARAGRAPH}
                >
                    {parse((props as ArticlePageParagraph)?.content ?? '')}
                </Typography>
            );
        }
        case 'atoms.topline': {
            return (
                <Typography
                    className={(props as ArticlePageTopline)?.cssClass}
                    inverted={inverted}
                    variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                >
                    {parse((props as ArticlePageTopline)?.toplineContent ?? '')}
                </Typography>
            );
        }
        case 'atoms.image': {
            return (
                <Media
                    src={(props as ArticlePageImageRoot)?.imageUpload?.url}
                    alt={(props as ArticlePageImageRoot)?.altText ?? ''}
                    className={(props as ArticlePageImageRoot)?.cssClass ?? ''}
                />
            );
        }
        case 'atoms.bookmark': {
            return (
                <ArticlePageBookmarkComp
                    id={(props as ArticlePageBookmark)?.identifier}
                />
            );
        }
        case 'atoms.primary-button': {
            return (
                <a
                    href={(props as ArticlePageButton)?.buttonUrl ?? ''}
                    target={(props as ArticlePageButton)?.external ? '_blank' : undefined}
                    rel={(props as ArticlePageButton)?.external ? 'noreferrer' : undefined}
                >
                    <Button
                        variant={ButtonVariantsEnum.PRIMARY}
                        inverted={inverted}
                    >
                        {(props as ArticlePageButton)?.buttonText ?? '' }
                    </Button>
                </a>
            );
        }
        case 'teaser.podcast-teaser': {
            return (
                <BasicCard
                    media={{
                        mediaUrl: (props as ArticlePagePodcastTeaser)?.podcastImage?.url ?? '',
                        contentType: MediaContentType.PNG
                    }}
                    category={EnumToLanguageMapper.categoryIdToCategoryEnum(1)}
                    type={BasicCardVariants.PODCAST_TEASER}
                    duration={
                        (props as ArticlePagePodcastTeaser)?.duration ?? ''
                    }
                    redirectUrl={
                        (props as ArticlePagePodcastTeaser)?.readMoreLink?.url ?? ''
                    }
                >
                    <PodcastTeaserCard
                        text={{
                            headline: (props as ArticlePagePodcastTeaser)
                                ?.headline?.headlineContent,
                            topline: (props as ArticlePagePodcastTeaser)
                                ?.topline?.toplineContent,
                            description: (props as ArticlePagePodcastTeaser)
                                ?.description?.content
                        }}
                        footer={{
                            onPrimaryAction: () => {
                                return;
                            },
                            date: (props as ArticlePagePodcastTeaser)?.date
                        }}
                        type={BasicCardVariants.PODCAST_TEASER}
                        isURLExternal={(props as ArticlePagePodcastTeaser)?.readMoreLink?.external}
                        moreInfoLinkText={
                            (props as ArticlePagePodcastTeaser)?.readMoreLink?.linkText ?? ''
                        }
                        redirectUrl={
                            (props as ArticlePagePodcastTeaser)?.readMoreLink?.url ?? ''
                        }
                    />
                </BasicCard>
            );
        }
        case 'teaser.content-teaser-small': {
            return (
                <TextOnlyTeaser
                    footer={{
                        onMoreInfo: () => {
                            return;
                        },
                        onAction: () => {
                            return;
                        }
                    }}
                    imageUrl={
                        (props as ArticlePageContentTeaserSmall)?.contentTeaserSmallImage?.url
                    }
                    inverted={inverted}
                    size={TextOnlyTeaserSizes.SMALL}
                    text={{
                        description: (props as ArticlePageContentTeaserSmall)
                            ?.paragraph.content,
                        headline: (props as ArticlePageContentTeaserSmall)
                            ?.headline,
                        subHeadline: (props as ArticlePageContentTeaserSmall)
                            ?.subtitle,
                        buttonText: (props as ArticlePageContentTeaserSmall)
                            ?.link?.linkText,
                    }}
                    buttonUrl={
                        (props as ArticlePageContentTeaserSmall)?.link
                    }
                    ghostButton={(props as ArticlePageContentTeaserSmall)?.ghostButton}
                    video={(props as ArticlePageContentTeaserSmall).video}
                />
            );
        }
        case 'atoms.spacer': {
            return (
                <Spacer
                    width="100%"
                    height={`${(props as ArticlePageSpacer)?.margin ?? 0}px`}
                />
            );
        }
        case 'atoms.video': {
            return (
                <VideoComponent
                    platform={(props as ArticlePageVideo).platform}
                    videoId={(props as ArticlePageVideo).videoId}
                    controls={(props as ArticlePageVideo).controls}
                    autoplay={(props as ArticlePageVideo).autoplay}
                />
            );
        }
        case 'organisms.content-teaser': {
            const buttons: any[] = [];
            (props as ArticlePageContentTeaserType)?.CTA.forEach(
                (item, index) => {
                    let buttonStyle: ButtonVariantsEnum;
                    switch (
                        (props as ArticlePageContentTeaserType)?.CTA[index]
                            ?.buttonStyle
                        ) {
                        case 'secondary':
                            buttonStyle = ButtonVariantsEnum.SECONDARY_OUTLINED;
                            break;
                        case 'inline':
                            buttonStyle = ButtonVariantsEnum.SECONDARY_FILLED;
                            break;
                        case 'outline':
                            buttonStyle = ButtonVariantsEnum.SECONDARY_OUTLINED;
                            break;
                        case 'text':
                            buttonStyle = ButtonVariantsEnum.SELECTED;
                            break;
                        default:
                            buttonStyle = ButtonVariantsEnum.PRIMARY;
                    }
                    buttons.push({
                        variant: buttonStyle,
                        children: (props as ArticlePageContentTeaserType)?.CTA[
                            index
                            ]?.buttonText,
                        buttonUrl:
                            (props as ArticlePageContentTeaserType)?.CTA[index]
                                ?.buttonUrl ?? '',
                        external: (props as ArticlePageContentTeaserType)?.CTA[index]?.external
                    });
                }
            );
            return (
                <TextImageTeaser
                    callToAction={buttons}
                    image={(props as ArticlePageContentTeaserType)?.Content_image}
                    video={(props as ArticlePageContentTeaserType).video}
                    headline={(props as ArticlePageContentTeaserType)?.Headline}
                    topline={{
                        toplineContent: (props as ArticlePageContentTeaserType)
                            ?.topline?.toplineContent,
                        cssClass: ''
                    }}
                    inverted={inverted}
                    description={{
                        content: (props as ArticlePageContentTeaserType)
                            ?.text_content,
                        cssClass: ''
                    }}
                    imagePosition={
                        (props as ArticlePageContentTeaserType)?.componentStyles
                            ?.isLeft
                            ? HorizontalPosition.LEFT
                            : HorizontalPosition.RIGHT
                    }
                    imgEdgePosition={
                        (props as ArticlePageContentTeaserType)?.imgEdgePosition
                    }
                />
            );
        }
        case 'teaser.variants-instances-teaser': {
            return (
                <ArticlePageVariantsInstancesTeaser
                    inverted={inverted}
                    headline={(props as ArticlePageVariantsInstancesTeaserType)?.headline}
                    elementType={(props as ArticlePageVariantsInstancesTeaserType)?.elementType}
                    elementIds={(props as ArticlePageVariantsInstancesTeaserType)?.elementIds}
                    teaserIdentifier={(props as ArticlePageVariantsInstancesTeaserType)?.id}
                    />
            );
        }
        case 'molecules.produkt-teaser': {
            return (
                <ArticlePageProductTeaser
                    variant={(props as ArticlePageProductTeaserType)?.variant}
                    headline={
                        (props as ArticlePageProductTeaserType)?.headline
                            ?.headlineContent
                    }
                    inverted={inverted}
                />
            );
        }
        case 'molecules.number-text-teaser': {
            return (
                <NumberTextTeaser
                    largeText={(props as ArticleNumberTextTeaser)?.largeText}
                    subText={(props as ArticleNumberTextTeaser)?.subText}
                />
            );
        }
        case 'organisms.newsletter': {
            return (
                <ArticlePageNewsletter
                    headline={(props as ArticlePageNewsletterType)?.headline}
                    description={
                        (props as ArticlePageNewsletterType)?.description
                    }
                    errorSnackbar={
                        (props as ArticlePageNewsletterType)?.errorSnackbar
                    }
                    successSnackbar={
                        (props as ArticlePageNewsletterType)?.successSnackbar
                    }
                    backgroundImage={
                        (props as ArticlePageNewsletterType)?.backgroundImage
                    }
                    inverted={(props as ArticlePageNewsletterType)?.inverted}
                    newsletterInput={{
                        id: 0,
                        fieldType: 'text',
                        fieldName: 'newsletter',
                        fieldLabel: (props as ArticlePageNewsletterType)
                            ?.newsletterPlaceholder,
                        fieldHint: (props as ArticlePageNewsletterType)
                            ?.newsletterPlaceholder,
                        fieldErrorMessages: {
                            id: 0,
                            required: (props as ArticlePageNewsletterType)
                                ?.inputRequiredError,
                            valid: (props as ArticlePageNewsletterType)
                                ?.validEmailError
                        }
                    }}
                />
            );
        }
        case 'molecules.faq-section': {
            return (
                <ArticlePageFaqBox
                    key={
                        (props as StrapiFaqContainer)?.Headline
                            ?.headlineContent ?? ''
                    }
                    Headline={(props as StrapiFaqContainer)?.Headline ?? ''}
                    fa_qs={(props as StrapiFaqContainer)?.fa_qs ?? []}
                />
            );
        }
        case 'molecules.quote-section': {
            return (
                <QuoteSection
                    inverted={inverted}
                    text={(props as ArticlePageQuoteSection)?.text?.content}
                    authorName={(props as ArticlePageQuoteSection)?.authorName}
                    authorTitle={(props as ArticlePageQuoteSection)?.authorTitle}
                    authorImage={(props as ArticlePageQuoteSection)?.authorImage}
                    alignment={(props as ArticlePageQuoteSection)?.alignment}
                />
            );
        }
        case 'organisms.head-image-section': {
            return (
                <HeadImageSection
                    imageUrl={(props as ArticlePageHeadImageSection)?.headImageMain?.url}
                    inverted={inverted}
                    alt={
                        (props as ArticlePageHeadImageSection)?.headImageMain
                            ?.alternativeText ?? ''
                    }
                    caption={(props as ArticlePageHeadImageSection).caption}
                />
            );
        }
        case 'organisms.head-image-with-text-section': {
            return (
                <HeadImageWithTextSection
                    imageUrl={(props as ArticlePageHeadImageWithTextSection)?.headImage?.url}
                    alt={
                        (props as ArticlePageHeadImageWithTextSection)
                            ?.headImage?.alternativeText ?? ''
                    }
                    caption={
                        (props as ArticlePageHeadImageWithTextSection).caption
                    }
                    inverted={inverted}
                    text={(props as ArticlePageHeadImageWithTextSection).text}
                    imagePosition={
                        (props as ArticlePageHeadImageWithTextSection)
                            ?.imagePosition === HorizontalPosition.LEFT
                            ? HorizontalPosition.LEFT
                            : HorizontalPosition.RIGHT
                    }
                />
            );
        }
        case 'organisms.flexcontainer': {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: (() => {
                            switch (
                                (props as ArticlePageFlexContainer)
                                    ?.horizontalAlignment
                                ) {
                                case HorizontalAlignment.RIGHT:
                                    return 'flex-end';
                                case HorizontalAlignment.CENTER:
                                    return 'center';
                                case HorizontalAlignment.SPACE_AROUND:
                                    return 'space-around';
                                case HorizontalAlignment.SPACE_BETWEEN:
                                    return 'space-between';
                                case HorizontalAlignment.SPACE_EVENLY:
                                    return 'space-evenly';
                                default:
                                    return 'flex-start';
                            }
                        })(),
                        alignItems: (() => {
                            switch (
                                (props as ArticlePageFlexContainer)
                                    ?.verticalAlignment
                                ) {
                                case VerticalAlignment.BOTTOM:
                                    return 'flex-end';
                                case VerticalAlignment.CENTER:
                                    return 'center';
                                case VerticalAlignment.BASELINE:
                                    return 'baseline';
                                case VerticalAlignment.STRETCH:
                                    return 'stretch';
                                default:
                                    return 'flex-start';
                            }
                        })(),
                        flexWrap: 'wrap'
                    }}
                >
                    {(() => {
                        const selectedArray = [
                            ...elements.slice(
                                index + 1,
                                index +
                                (props as ArticlePageFlexContainer)
                                    ?.numberOfChildren +
                                1
                            )
                        ];

                        const preArray = elements?.slice(0, index + 1);
                        const postArray = elements?.slice(
                            index +
                            (props as ArticlePageFlexContainer)
                                .numberOfChildren +
                            1,
                            elements.length - 1
                        );

                        elements = [...preArray, ...postArray];

                        return selectedArray.map((element, newIndex, array) => (
                            <div
                                style={{
                                    maxWidth: '312px',
                                    margin: `${
                                        (props as ArticlePageFlexContainer)
                                            ?.columnGap ?? 0
                                    }px ${
                                        (props as ArticlePageFlexContainer)
                                            ?.rowGap ?? 0
                                    }px`
                                }}
                                key={newIndex}
                            >
                                {resolveElementForData(
                                    element,
                                    array,
                                    newIndex,
                                    inverted
                                )}
                            </div>
                        ));
                    })()}
                </div>
            );
        }
        case 'molecules.contact-form': {
            return (
                <ContactForm
                    inverted={inverted}
                />
            );
        }
        case 'organisms.register-form-light': {
            return (
                <RegisterFormLight
                    {...(props as ArticlePageRegisterFormLight)}
                />
            );
        }
    }
};

interface Props {
    type: ArticlePageTypes;
    pageData?: ArticlePageData;
}

export const ArticlePage: React.FC<Props> & {
    dataHooks: any;
} = ({pageData, type}: Props) => {
    let data: ArticlePageData | undefined;
    if (pageData) {
        data = pageData;
    } else {
        data = useArticlePage();
    }

    const router = useRouter();

    useEffect(() => {
        const url = location?.href ?? '';

        const idInUrl = url.match(/#[A-Za-zäöüÄÖÜ]+/)?.[0];

        if (
            !idInUrl &&
            (type === ArticlePageTypes.ARTICLE_PAGE_STRAPIID || type === ArticlePageTypes.CONFIRMATION_PAGE_STRAPIID) &&
            data &&
            data?.urlReadablePageTitle
        ) {
            const prefix = (type === ArticlePageTypes.ARTICLE_PAGE_STRAPIID) ? '/s/' : '/c/';
            router.push(prefix + data?.urlReadablePageTitle);
        }
    }, [data]);

    if (data) {
        return (
            <div className={data.inverted ? styles['inverted'] : ''}>
                <HeaderFooterWrapper
                    header={
                        data?.headerType === 'TEMP' ? (
                            <ArticlePageTempHeader
                                forStaticPage={true}
                                pageType={type}
                                availableLanguages={data?.localizations}
                            />
                        ) : (
                            <DefaultHeader
                                forStaticPage={true}
                                pageType={type}
                                availableLanguages={data?.localizations}
                            />
                        )
                    }
                    stage={
                        <Stage
                            inverted={
                                data?.stage?.componentStyles?.inverted ?? true
                            }
                            logo={
                                data?.stage?.showLogo ? (
                                    <LogoText width="400px" fill={'white'}/>
                                ) : null
                            }
                            description={data?.stage?.paragraph?.content ?? ''}
                            background={data?.stage?.background?.url}
                            verticalOrientation={
                                data?.stage?.componentStyles
                                    ?.verticaltextOrientation ?? 'CENTER'
                            }
                            title={data?.stage?.headline.headlineContent}
                            titleVariant={data?.stage?.headline?.style}
                            size={data?.stage?.componentStyles?.size ?? 'Large'}
                            orientation={
                                data?.stage?.componentStyles?.textAlign ??
                                'CENTER'
                            }
                            buttons={
                                data?.stage?.cta
                                    ? [
                                        {
                                            buttonUrl:
                                            data?.stage?.cta?.buttonUrl,
                                            children:
                                            data?.stage?.cta?.buttonText,
                                            external: data?.stage?.cta?.external,
                                        }
                                    ]
                                    : undefined
                            }
                        />
                    }
                >
                    <div
                        className={`${styles['article-page']}
                    ${styles[`${data.bodyVariant}`]}`}
                    >
                        <MetaTagsResolver tags={data.meta} articlePageType={type}/>
                        {(() => {
                            const elements = data?.body;

                            let flexEndPos: number | null = null;
                            let isFlexRoot = false;
                            return elements.map((element, index) => {
                                if (
                                    element.__component ===
                                    'organisms.flexcontainer'
                                ) {
                                    isFlexRoot = true;
                                    flexEndPos =
                                        index +
                                        (element as ArticlePageFlexContainer)
                                            .numberOfChildren;
                                } else {
                                    isFlexRoot = false;
                                }
                                if (flexEndPos && index > flexEndPos) {
                                    flexEndPos = null;
                                }
                                if (flexEndPos === null || isFlexRoot)
                                    return (
                                        <div
                                            key={index}
                                            className={element.__component}
                                            style={
                                                element?.__component ===
                                                'atoms.bookmark'
                                                    ? {
                                                        margin: 'unset',
                                                        width: 0
                                                    }
                                                    : undefined
                                            }
                                        >
                                            {resolveElementForData(
                                                element,
                                                elements,
                                                index,
                                                data?.inverted ?? false
                                            )}
                                        </div>
                                    );
                            });
                        })()}
                    </div>
                </HeaderFooterWrapper>
            </div>
        );
    } else {
        return <div/>;
    }
};

ArticlePage.dataHooks = [
    useArticlePage,
    ...MyApp.dataHooks,
    ...ContactForm.dataHooks,
    ...HeaderFooterWrapper.dataHooks,
    ...ArticlePageTempHeader.dataHooks,
    ...RegisterFormLight.dataHooks,
    ...MetaTagsResolver.dataHooks
];
