import React from 'react';

import {StrapiImage} from '../../../api/models/strapi/strapi-image';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import { Media } from '../media/media';
import {Typography} from '../typography/typography';
import styles from './quote-section.module.scss';

export interface QuoteSectionProps {
    id?: string;
    text: string;
    authorName?: string;
    authorTitle?: string;
    authorImage?: StrapiImage;
    alignment?: 'left' | 'center' | 'right';
    inverted?: boolean;
}

const QuoteSection = (props: QuoteSectionProps): JSX.Element => {
    return (
        <div id={props?.id} className={`${styles['quote-section']} ${props.alignment ? styles[props.alignment] : ''}`}>
            <div>
                {props.authorImage
                    ? <Media
                        className={styles['author-image']}
                        src={props.authorImage.url}
                        alt={'Author image'}
                        hexagon
                    />
                    : undefined
                }
                <div className={styles['quote-wrapper']}>
                    <Typography
                        className={`${styles['quote']} ${props.inverted ? styles['inverted'] : ''}`}
                        variant={TypographyVariantsEnum.QUOTE}
                        inverted={props.inverted}
                    >
                        {props.text}
                    </Typography>
                </div>
                {
                    props.authorName
                        ? <Typography
                            className={styles['author-name']}
                            inverted={props.inverted}
                            variant={TypographyVariantsEnum.HEADING6}>
                            {props.authorName}
                        </Typography>
                        : undefined
                }
                {
                    props.authorTitle
                        ?
                        <Typography
                            className={styles['author-title']}
                            inverted={props.inverted}
                            variant={TypographyVariantsEnum.CAPTION}>
                            {props.authorTitle}
                        </Typography>
                        : undefined
                }
            </div>
        </div>
    );
};

export default QuoteSection;