import React from 'react';

import {StrapiHeadline} from '../../../api/models/strapi/strapi-headline';
import {StrapiImage} from '../../../api/models/strapi/strapi-image';
import {StrapiParagraphProps} from '../../../api/models/strapi/strapi-paragraph-props';
import {StrapiTopline} from '../../../api/models/strapi/strapi-topline';
import {StrapiVideo} from '../../../api/models/strapi/strapi-video';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {HorizontalPosition} from '../../../utils/_enums/horizontal-position.enum';
import {ImageEdgePosition} from '../../../utils/_enums/image-edge-position.enum';
import {Button, ButtonProps} from '../button/button';
import {LinkBase} from '../link/link-base/link-base';
import { Media } from '../media/media';
import TeaserCardText from '../teaser-card-components/teaser-card-text/teaser-card-text';
import VideoComponent from '../video-component/video-component';
import styles from './text-image-teaser.module.scss';

export interface TextImageTeaserProps {
    id?: string;
    imagePosition: HorizontalPosition;
    image: StrapiImage;
    video?: StrapiVideo;
    headline: StrapiHeadline;
    topline: StrapiTopline;
    description: StrapiParagraphProps;
    callToAction?: ButtonProps[];
    redirectUrl?: string;
    imgEdgePosition?: ImageEdgePosition;
    inverted?: boolean;
}

const TextImageTeaser = (props: TextImageTeaserProps): JSX.Element => {
    return (
        <div
            id={props?.id}
            className={`${styles['text-image-teaser']} ${
                props.imgEdgePosition ? styles[`${props.imgEdgePosition}`] : ''
            }`}
        >
            <div
                className={
                    props.imagePosition === HorizontalPosition.LEFT
                        ? styles['right-wrapper']
                        : styles['left-wrapper']
                }
            >
                <TeaserCardText
                    headline={props.headline.headlineContent}
                    topline={props.topline.toplineContent}
                    description={props.description.content}
                    variant={BasicCardVariants.TEXT_IMAGE_TEASER}
                    inverted={props.inverted}
                />
                {props.callToAction && (
                    <div className={styles['buttons']}>
                        {props?.callToAction?.map((args) => (
                            <LinkBase
                                key={args.buttonUrl}
                                href={args.buttonUrl ?? ''}
                                external={args?.external ?? false}
                            >
                                <Button
                                    key={args.children as string}
                                    inverted={props.inverted}
                                    variant={args.variant}
                                    {...args}
                                />
                            </LinkBase>
                        ))}
                    </div>
                )}
            </div>

            <div
                className={
                    props.imagePosition === HorizontalPosition.LEFT
                        ? styles['left-wrapper-img']
                        : styles['right-wrapper-img']
                }
            >
                {!props?.video?.videoId && (
                <Media
                    alt={props.image?.alternativeText ?? 'image-teaser-alt'}
                    src={props?.image?.url ?? ''}
                    objectFit={'contain'}
                />
                )}
                
                {props?.video?.platform && props?.video?.videoId &&(                
                    <VideoComponent 
                        platform={props?.video?.platform}
                        videoId={props?.video?.videoId}
                        controls={props?.video?.controls}
                        autoplay={props?.video?.autoplay}
                    />
                )}


            </div>
        </div>
    );
};

export default TextImageTeaser;
