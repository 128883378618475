import Image from 'next/image';
import React from 'react';

import {StrapiHeadline} from '../../../api/models/strapi/strapi-headline';
import {StrapiImage} from '../../../api/models/strapi/strapi-image';
import {StrapiParagraphProps} from '../../../api/models/strapi/strapi-paragraph-props';
import {StrapiTextFieldProps} from '../../../api/models/strapi/strapi-text-field-props';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import NewsletterInput from '../../_shared/newsletter-input/newsletter-input';
import {Typography} from '../../_shared/typography/typography';
import styles from './article-page-newsletter.module.scss';

export interface ArticlePageNewsletterProps {
    id?: string;
    headline: StrapiHeadline;
    description: StrapiParagraphProps;
    newsletterInput?: StrapiTextFieldProps;
    errorSnackbar: string;
    successSnackbar: string;
    backgroundImage: StrapiImage;
    inverted?: boolean;
}

const ArticlePageNewsletter = (props: ArticlePageNewsletterProps) => {
    return props.headline && props.description && props.newsletterInput ? (
        <div id={props?.id} className={styles['article-page-newsletter']}>
            {
                props?.backgroundImage?.imageUpload?.url
                    ? <Image
                        src={props.backgroundImage.imageUpload.url}
                        layout={'fill'}
                        objectPosition={'top'}
                        objectFit={'cover'}
                    />
                    : undefined
            }
            <div className={styles['content']}>
                <Typography
                    inverted={props.inverted}
                    variant={TypographyVariantsEnum.HEADING3_BOLD}
                    className={styles['newsletter-headline']}
                    element={props.headline.cssClass ?? 'h3'}
                >
                    {props.headline.headlineContent}
                </Typography>
                <Typography inverted={props.inverted}>
                    {props.description.content}
                </Typography>
                <div className={styles['newsletter-input-wrapper']}>
                    <NewsletterInput
                        inverted={props.inverted}
                        className={styles['newsletter-input']}
                        inputTexts={props.newsletterInput}
                        errorMessage={props.errorSnackbar}
                        successMessage={props.successSnackbar}
                        otherIcon={['fas', 'paper-plane']}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div/>
    );
};

export default ArticlePageNewsletter;
