import React from 'react';

import {useCardText} from '../../../api/hooks/useCardText.hook';
import {StrapiButtonProps} from '../../../api/models/strapi/strapi-button-props';
import {StrapiHeadline} from '../../../api/models/strapi/strapi-headline';
import {StrapiVideo} from '../../../api/models/strapi/strapi-video';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {ButtonVariantsEnum} from '../../../utils/_enums/button-variants.enum';
import {TextOnlyTeaserSizes} from '../../../utils/_enums/text-only-teaser-sizes.enum';
import {mapStrapiHeadline} from '../../../utils/_helper/strapi-mapper';
import {Button} from '../button/button';
import {Link} from '../link/link';
import {LinkBase} from '../link/link-base/link-base';
import TeaserCardText from '../teaser-card-components/teaser-card-text/teaser-card-text';
import VideoComponent from '../video-component/video-component';
import styles from './text-only-teaser.module.scss';
import { Media } from '../media/media';

export interface TextOnlyTeaserProps {
    imageUrl?: string;
    text: {
        headline: StrapiHeadline;
        subHeadline: StrapiHeadline;
        description: string;
        buttonText?: string;
    };
    footer: {
        onMoreInfo?: () => void;
        onAction?: () => void;
    };
    size: TextOnlyTeaserSizes;
    buttonUrl?: any;
    inverted?: boolean;
    ghostButton?: StrapiButtonProps;
    video?: StrapiVideo;
}

const TextOnlyTeaser: React.FC<TextOnlyTeaserProps> = (props: TextOnlyTeaserProps): JSX.Element => {
    const data = useCardText();
    return (
        <div
            className={`${styles['text-only-teaser-wrapper']}
            ${styles[`text-only-teaser-wrapper-${props.size}`]}`}
        >
            {props.imageUrl && !props?.video?.videoId && (
                <Media src={props?.imageUrl ?? ''} objectFit={'contain'}/>
            )}

            {props?.video?.platform && props?.video?.videoId && (
                <VideoComponent
                    platform={props?.video?.platform}
                    videoId={props?.video?.videoId}
                    controls={props?.video?.controls}
                    autoplay={props?.video?.autoplay}
                />
            )}

            <div className={styles['text']}>
                <TeaserCardText
                    headline={props.text.headline.headlineContent}
                    headlineElement={props.text.headline.element}
                    headlineVariant={mapStrapiHeadline(props.text.headline?.style)}
                    headlineStyle={{
                        textAlign:
                            props.text.headline.textAlign?.toLowerCase?.() as
                                | 'left'
                                | 'right'
                                | 'center'
                                | 'justify'
                                | undefined
                    }}
                    subHeadline={props.text?.subHeadline?.headlineContent}
                    subHeadlineElement={props.text?.subHeadline?.element}
                    subHeadlineVariant={mapStrapiHeadline(props.text?.subHeadline?.style)}
                    subHeadlineStyle={{
                        textAlign:
                            props.text?.subHeadline?.textAlign?.toLowerCase?.() as
                                | 'left'
                                | 'right'
                                | 'center'
                                | 'justify'
                                | undefined
                    }}
                    description={props.text.description}
                    inverted={props.inverted}
                    variant={
                        props.size === TextOnlyTeaserSizes.LARGE
                            ? BasicCardVariants.TEXT_ONLY_TEASER_LARGE
                            : BasicCardVariants.TEXT_ONLY_TEASER_NORMAL
                    }
                />
            </div>

            <div className={styles['footer']}>
                {props.footer.onAction && props?.ghostButton?.buttonText && (
                    <div>
                        <LinkBase
                            href={props?.ghostButton?.buttonUrl ?? ''}
                            external={props?.ghostButton?.external}
                        >
                            <Button
                                variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                                inverted={props.inverted}>
                                {props?.ghostButton?.buttonText}
                            </Button>
                        </LinkBase>
                    </div>
                )}
                {props.footer.onMoreInfo && props?.buttonUrl ? (
                    <div className={styles['link']}>
                        <Link
                            href={props?.buttonUrl?.url ?? ''}
                            inverted={props.inverted}
                            external={props?.buttonUrl?.external}
                        >
                            {props.text.buttonText
                                ? props.text.buttonText
                                : data?.readMore
                                    ? data.readMore
                                    : ''}
                        </Link>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default TextOnlyTeaser;
