import React from 'react';

import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Media } from '../media/media';
import { Typography } from '../typography/typography';
import styles from './head-image-section.module.scss';

export interface HeadImageSectionProps {
    id?: string;
    imageUrl?: string;
    alt?: string;
    caption: string;
    inverted?: boolean;
}

const HeadImageSection = (props: HeadImageSectionProps): JSX.Element => {
    return (
        <div id={props?.id} className={styles['head-image-wrapper']}>
            <Media
                src={props?.imageUrl ?? ''}
                alt={props?.alt}
            />

            <Typography
                inverted={props?.inverted ?? false}
                variant={TypographyVariantsEnum.CAPTION}
                element={'span'}
            >
                {props?.caption ?? ''}
            </Typography>
        </div>
    );
};

export default HeadImageSection;
