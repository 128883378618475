import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchStaticPageVariants, useAppSelector} from '../../../redux';
import {ArticlePageProductTeaserVariant} from '../../../utils/_enums/article-page-product-teaser-variant.enum';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {FilterExpression} from '../../../utils/_enums/filter-expression.enum';
import {dateToISOString} from '../../../utils/_helper/time-helper';
import {FilterItem} from '../../../utils/_models/filter-item';
import Teaser from '../../_shared/teaser/teaser';
import TeaserCard from '../../_shared/teaser-card/teaser-card';

export interface ProductTeaserProps {
    id?: string;
    variant?: ArticlePageProductTeaserVariant;
    headline: string;
    inverted?: boolean;
}

export const ArticlePageProductTeaser: React.FC<ProductTeaserProps> = (
    {
        headline,
        id,
        inverted,
        variant: variantType
    }
) => {
    const dispatch = useDispatch();

    const variants = useAppSelector((state) => (state.productSlice.staticPageVariants));

    useEffect(() => {
        fetchVariantsWithFilter(0, 6);
    }, []);

    const fetchVariantsWithFilter = (offset: number, pageSize: number) => {
        let filter: FilterItem[] = [
            {
                value: pageSize.toString(),
                param: 'size',
                comparisonType: FilterExpression.none
            },
            {
                value: offset.toString(),
                param: 'offset',
                comparisonType: FilterExpression.none
            }
        ]

        if (variantType === ArticlePageProductTeaserVariant.UNIQUE_INSTANCES) {
            filter = [
                ...filter,
                {
                    value: '1',
                    param: 'mintingCount',
                    comparisonType: FilterExpression.equals
                },
                {
                    value: 'OPEN',
                    param: 'status',
                    comparisonType: FilterExpression.equals
                }
            ];
        } else {
            filter = [
                ...filter,
                {
                    value: encodeURIComponent(dateToISOString(new Date())),
                    param: 'saleFrom',
                    comparisonType: FilterExpression.greaterThan
                },
                {
                    param: 'sort',
                    comparisonType: FilterExpression.none,
                    value: 'salesAttribute.saleFrom,asc'
                }
            ];
        }
        dispatch(fetchStaticPageVariants(filter));
    }

    return (
        <Teaser
            id={id}
            headline={headline}
            maxCards={variants?.maxNumber}
            headlineElement={'h2'}
            inverted={inverted}
            pending={variants.pending}
            cards={
                variants?.data?.map?.((variant) => (
                    <TeaserCard
                        key={`article-page-variant-teaser-${variant.id}`}
                        variant={variant}
                        inverted={inverted}
                    />
                )) ?? []
            }
            onLoadNewItems={(data) => {
                fetchVariantsWithFilter(data.pageOrOffset, data.size);
            }}
            type={BasicCardVariants.BASIC_CARD_SMALL}
        />
    );
};

ArticlePageProductTeaser.defaultProps = {
    variant: ArticlePageProductTeaserVariant.FUTURE_RELEASE
};
