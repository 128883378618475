import React from 'react';

import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import TeaserCardFooter from '../teaser-card-components/teaser-card-footer/teaser-card-footer';
import TeaserCardText from '../teaser-card-components/teaser-card-text/teaser-card-text';
import styles from './podcast-teaser-card.module.scss';

export interface PodcastTeaserCardProps {
    text: {
        headline: string;
        topline: string;
        description?: string;
        readMore?: () => void;
    },
    footer?: {
        date?: string;
        onPrimaryAction?: () => void;
    };
    label?: string[],
    type: BasicCardVariants;
    redirectUrl?: string;
    isURLExternal?: boolean;
    moreInfoLinkText?: string;
}

const PodcastTeaserCard = (props: PodcastTeaserCardProps): JSX.Element => {

    return (
        <div className={`${styles['podcast-teaser-card-wrapper']} ${styles[`${props.type ? props.type : ''}`]}`}>

            <div className={styles['teaser-card-text']}>
                {/* topline (Author name...), headline, description */}

                {props.label && props.label.length > 0 &&
                    <div className={styles['label-wrapper']}>
                        {props.label.map((label, index) => (
                            <div className={styles['label-container']} key={label + index}>
                                <div className={styles['label']}>
                                    {label.toUpperCase()}
                                </div>
                            </div>
                        ))}
                    </div>
                }

                <TeaserCardText
                    {...props.text}
                    variant={props.type}
                    headlineVariant={TypographyVariantsEnum.HEADING4_BOLD}
                    redirectUrl={props.redirectUrl}
                />
            </div>

            {props.footer &&
                <TeaserCardFooter
                    {...props.footer}
                    type={props.type}
                    redirectUrl={props.redirectUrl}
                    isURLExternal={props.isURLExternal}
                    moreInfoLinkText={props.moreInfoLinkText}
                    onMoreInfo={props.footer.onPrimaryAction}
                />
            }

        </div>
    );
};

export default PodcastTeaserCard;
