import React from 'react';

import {StrapiFaqContainer} from '../../../api/models/strapi/strapi-faq-container';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {mapStrapiHeadline} from '../../../utils/_helper/strapi-mapper';
import Accordion from '../accordion/accordion';
import { Media } from '../media/media';
import {Spacer} from '../spacer/spacer';
import {Typography} from '../typography/typography';
import styles from './faq-box.module.scss';

const FaqBox: React.FC<StrapiFaqContainer & { className?: string }> = (
    props
) => {
    return (
        <div
            className={styles['faq-box'] + ' ' + props?.className}
            data-testid="faq-box"
        >
            <Typography
                variant={
                    props.Headline?.style
                        ? mapStrapiHeadline(props.Headline.style)
                        : TypographyVariantsEnum.HEADING2_BOLD
                }
                element={props.Headline?.element ?? undefined}
                style={{
                    textAlign: props.Headline?.textAlign?.toLowerCase?.() as
                        | 'left'
                        | 'right'
                        | 'center'
                        | 'justify'
                        | undefined
                }}
                inverted={props.inverted}
            >
                {props.Headline?.headlineContent}
            </Typography>

            <Spacer height="40px"/>

            {props.fa_qs?.map(
                ({faqs: {description, headline, image, initialOpen}}) => {
                    return (
                        <Accordion
                            initialOpen={initialOpen ?? false}
                            label={headline}
                            key={headline}
                        >
                            <div className={styles['faq-item-content']}>
                                <div className={styles['faq-item-description-wrapper']}>
                                    <Typography
                                        variant={TypographyVariantsEnum.BODY}
                                        className={styles['faq-item-description']}
                                    >
                                        {description}
                                    </Typography>
                                </div>
                                {
                                    image
                                        ? <div className={styles['faq-item-image']}>
                                            <Media
                                                alt={image.alternativeText}
                                                objectFit={'contain'}
                                                src={image.url}
                                            />
                                        </div>
                                        : undefined
                                }
                            </div>
                        </Accordion>
                    );
                }
            )}
        </div>
    );
};

export default FaqBox;
