import { createDataHook } from 'next-data-hooks';

import { backend } from '../../utils/_config/axios';
import { ArticlePageProductTeaserVariant } from '../../utils/_enums/article-page-product-teaser-variant.enum';
import { HorizontalPosition } from '../../utils/_enums/horizontal-position.enum';
import { ImageEdgePosition } from '../../utils/_enums/image-edge-position.enum';
import { StrapiTeaserElementTypes } from '../../utils/_enums/strapi-teaser-element-types.enum';
import { StrapiHeadline } from '../models/strapi/strapi-headline';
import { StrapiImage } from '../models/strapi/strapi-image';
import { StrapiLinkProps } from '../models/strapi/strapi-link-props';
import { StrapiMeta } from '../models/strapi/strapi-meta';
import { StrapiParagraphProps } from '../models/strapi/strapi-paragraph-props';
import { StrapiStage } from '../models/strapi/strapi-stage';
import { StrapiTopline } from '../models/strapi/strapi-topline';
import { StrapiVideo } from '../models/strapi/strapi-video';

export const useArticlePage = createDataHook(
    'ArticlePage',
    async ({ locale, params }) => {
        const localeRes = !locale || locale === 'catchAll' ? 'en' : locale;

        if (params) {
            const key = Object.keys(params)[0];
            const value =
                Object.values(params).length > 1
                    ? Object.values(params)[1]
                    : Object.values(params)[0];
            const result = await backend().get<ArticlePageData[]>(
                `api/strapi/${
                    key === 'articlePage'
                        ? 'article-pages'
                        : 'confirmation-pages'
                }?${
                    Object.values(params).length > 1
                        ? `id=${value}&_locale=${localeRes}`
                        : `_locale=${localeRes}&urlReadablePageTitle=${value}`
                }`
            );
            return result.data?.[0] ?? null;
        }
    }
);

export interface ArticlePageData {
    headerType?: 'DEFAULT' | 'TEMP';
    id: number;
    locale: 'en' | 'de' | 'es';
    urlReadablePageTitle: string | null;
    pageTitle: string;
    stage: StrapiStage;
    bodyVariant: string | null;
    alignment: HorizontalPosition;
    inverted: boolean;
    body: (
        | (StrapiHeadline & { __component: 'atoms.headline' })
        | ArticlePageParagraph
        | ArticlePageContentTeaser
        | ArticlePageImageRoot
        | ArticlePageContentTeaserSmall
        | ArticlePageFlexContainer
        | ArticlePagePodcastTeaser
        | ArticlePageVariantsInstancesTeaser
        | ArticlePageQuoteSection
        | ArticlePageHeadImageSection
        | ArticlePageHeadImageWithTextSection
        | ArticlePageBookmark
    )[];

    localizations: {
        id: number;
        locale: string;
        published_at: string;
    }[];
    meta: StrapiMeta;
}

export interface ArticlePageParagraph {
    __component: 'atoms.paragraph';
    id: number;
    cssClass?: string;
    content: string;
}

export interface ArticlePageTopline {
    __component: 'atoms.topline';
    id: number;
    cssClass?: string;
    toplineContent: string;
}

export interface ArticlePageButton {
    __component: 'atoms.primary-button';
    id: number;
    buttonText: string;
    buttonUrl: string;
    buttonStyle: string;
    external?: boolean;
}

export interface ArticlePageSpacer {
    __component: 'atoms.spacer';
    id: number;
    margin: number;
}

export interface ArticlePageVideo extends StrapiVideo {
    __component: 'atoms.video';
}

export interface ArticlePageContentTeaser {
    __component: 'organisms.content-teaser';
    id: number;
    Headline: StrapiHeadline;
    text_content: string;
    topline: StrapiTopline;
    video: StrapiVideo;
    CTA: ArticlePageButton[];
    Content_image: StrapiImage;
    componentStyles?: { isLeft: boolean };
    imgEdgePosition?: ImageEdgePosition;
}

export interface ArticlePageContentTeaserSmall {
    __component: 'teaser.content-teaser-small';
    id: number;
    headline: StrapiHeadline;
    paragraph: StrapiParagraphProps;
    subtitle: StrapiHeadline;
    video: StrapiVideo;
    contentTeaserSmallImage: StrapiImage;
    link: { linkText: string; url: string; external: boolean };
    ghostButton?: { buttonText: string; buttonUrl: string };
}

export interface ArticlePageProductTeaser {
    __component: 'molecules.produkt-teaser';
    id: number;
    headline: StrapiHeadline;
    variant?: ArticlePageProductTeaserVariant;
}

export interface ArticlePageImageRoot {
    __component: 'atoms.image';
    id: number;
    cssClass: string;
    altText: string;
    imageUpload: StrapiImage;
}

export interface ArticlePageFaqSection {
    __component: 'molecules.faq-section';
    id: number;
    Headline: string | null;
    fa_qs: { id: number }[];
}

export interface ArticlePageFlexContainer {
    __component: 'organisms.flexcontainer';
    numberOfChildren: number;
    horizontalAlignment: HorizontalAlignment;
    verticalAlignment: VerticalAlignment;
    columnGap?: number;
    rowGap?: number;
}

export enum HorizontalAlignment {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
    SPACE_AROUND = 'SPACE_AROUND',
    SPACE_BETWEEN = 'SPACE_BETWEEN',
    SPACE_EVENLY = 'SPACE_EVENLY'
}

export enum VerticalAlignment {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    CENTER = 'CENTER',
    STRETCH = 'STRETCH',
    BASELINE = 'BASELINE'
}

export interface ArticlePagePodcastTeaser {
    __component: 'teaser.podcast-teaser';
    id: number;
    headline: StrapiHeadline;
    description: StrapiParagraphProps;
    topline: StrapiTopline;
    podcastImage: StrapiImage;
    duration: string;
    date: string;
    readMoreLink: {
        linkText: string;
        url?: string;
        external: boolean;
    };
    componentStyles?: { isLeft: boolean };
}

export interface ArticlePageVariantsInstancesTeaser {
    __component: 'teaser.variants-instances-teaser';
    id: number;
    headline: StrapiHeadline;
    elementType: StrapiTeaserElementTypes;
    elementIds: [
        {
            elementId: number;
        }
    ];
}

export interface ArticlePageNewsletter {
    __component: 'organisms.newsletter';
    id: number;
    headline: StrapiHeadline;
    description: StrapiParagraphProps;
    backgroundImage: StrapiImage;
    errorSnackbar: string;
    successSnackbar: string;
    newsletterPlaceholder: string;
    inputRequiredError: string;
    validEmailError: string;
    inverted: boolean;
}

export interface ArticleNumberTextTeaser {
    __component: 'molecules.number-text-teaser';
    id: number;
    largeText: string;
    subText: string;
}

export interface ArticlePageQuoteSection {
    __component: 'molecules.quote-section';
    id: number;
    text: StrapiParagraphProps;
    authorImage?: StrapiImage;
    authorName?: string;
    authorTitle?: string;
    alignment?: 'left' | 'center' | 'right';
}

export interface ArticlePageHeadImageSection {
    __component: 'organisms.head-image-section';
    id: number;
    headImageMain: StrapiImage;
    caption: string;
}

export interface ArticleContactForm {
    __component: 'molecules.contact-form';
    inverted: boolean;
}

export interface ArticlePageHeadImageWithTextSection {
    __component: 'organisms.head-image-with-text-section';
    id: number;
    headImage: StrapiImage;
    caption: string;
    text: string;
    imagePosition: string;
}

export interface ArticlePageRegisterFormLight {
    __component: 'organisms.register-form-light';
    id: number;
    buyButton: string;
    noVariantSelectedMsg: string;
    teaserHeadline: StrapiHeadline;
    buyDialogHeadline: StrapiHeadline;
    buyDialogDescription: StrapiParagraphProps;
    inverted?: boolean;
    teaserHeadlineAndParagraphHeadline?: StrapiHeadline;
    teaserHeadlineAndParagraphParagraph?: StrapiParagraphProps;
    teaserHeadlineAndParagraphLegalLink?: StrapiLinkProps;
    legalDocumentsHeadline?: StrapiHeadline;
    legalLinkText?: string;
    legalLinkAsset?: StrapiImage;
    expirationRightOfWithdrawal: string;
    expirationRightOfWithdrawalRequired: string;
    connectWallet: string;
    invalidEthereumNetwork: string;
    metaMaskNotInstalled: StrapiParagraphProps;
}

export interface ArticlePageBookmark {
    __component: 'atoms.bookmark';
    identifier: string;
}
