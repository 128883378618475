import React from 'react';

import { useResolveFaqItems } from '../../../api/hooks/useResolveFaqItems.hook';
import { StrapiFaqContainer } from '../../../api/models/strapi/strapi-faq-container';
import FaqBox from '../../_shared/faq-box/faq-box';
import styles from './article-page-faq-box.module.scss';

export const ArticlePageFaqBox: React.FC<StrapiFaqContainer> = (props) => {
    const { data: faqs } = useResolveFaqItems(props?.fa_qs ?? []);

    return (
        <FaqBox
            {...props}
            className={styles['article-page-faq-box']}
            fa_qs={faqs ?? []}
        />
    );
};
