import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {DetailedInstance} from '../../../api/models/detailed-instance';
import {Instance} from '../../../api/models/instance';
import {StrapiHeadline} from '../../../api/models/strapi/strapi-headline';
import {Variant} from '../../../api/models/variant';
import {fetchElements, useAppSelector} from '../../../redux';
import {ArticlePageProductTeaserVariant} from '../../../utils/_enums/article-page-product-teaser-variant.enum';
import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {FilterExpression} from '../../../utils/_enums/filter-expression.enum';
import {StrapiTeaserElementTypes} from '../../../utils/_enums/strapi-teaser-element-types.enum';
import {mapStrapiHeadline} from '../../../utils/_helper/strapi-mapper';
import {FilterItem} from '../../../utils/_models/filter-item';
import {TeaserElementModel} from '../../../utils/_models/teaser-element-model';
import Teaser from '../../_shared/teaser/teaser';
import TeaserCard from '../../_shared/teaser-card/teaser-card';

export interface ArticlePageVariantsInstancesTeaserProps {
    id?: string;
    headline: StrapiHeadline;
    inverted: boolean;
    elementType: StrapiTeaserElementTypes;
    elementIds: [
        {
            elementId: number;
        }
    ];
    teaserIdentifier: number;
    variant?: ArticlePageProductTeaserVariant;
}

export const ArticlePageVariantsInstancesTeaser: React.FC<ArticlePageVariantsInstancesTeaserProps> =
    ({
         headline,
         variant: variantType,
         elementType,
         elementIds,
         inverted,
         teaserIdentifier
     }) => {
        const dispatch = useDispatch();

        const {
            elements: elements,
            user,
            walletId,
            addToBasketModelPending,
            addToBasketStatus
        } = useAppSelector((state: any) => ({
            elements: state.productSlice.variantInstancesElements.find(
                (item: TeaserElementModel<Variant[] | Instance[]>) =>
                    item.teaserIdentifier === teaserIdentifier
            ),
            user: state.userSlice.user,
            walletId: state.blockchainSlice.walletId,
            addToBasketModelPending: state.checkoutSlice.pending,
            addToBasketStatus: state.checkoutSlice.addToBasketStatus
        }));

        useEffect(() => {
            fetchElementsByIds(0, 6);
        }, []);

        const fetchElementsByIds = (offset: number, pageSize: number) => {
            let filter: FilterItem[] = [
                {
                    value: pageSize.toString(),
                    param: 'size',
                    comparisonType: FilterExpression.none
                },
                {
                    value: offset.toString(),
                    param: 'offset',
                    comparisonType: FilterExpression.none
                }
            ];

            elementIds.forEach((element) => {
                filter = [
                    ...filter,
                    {
                        value: element?.elementId?.toString() ?? '',
                        param: 'id',
                        comparisonType: FilterExpression.equals
                    }
                ];
            });
            dispatch(
                fetchElements({
                    filter,
                    elementType,
                    identifier: teaserIdentifier
                })
            );
        };

        if (elements && elements.data.length > 0) {
            if (elementType === StrapiTeaserElementTypes.VARIANTS) {
                return (
                    <div>
                        <Teaser
                            headline={headline.headlineContent}
                            headlineStyle={headline.textAlign}
                            inverted={inverted}
                            maxCards={elements.maxNumber}
                            headlineElement={headline.element}
                            headlineVariant={mapStrapiHeadline(headline.style)}
                            withNumbers={true}
                            hideTeaserButtons={false}
                            pending={elements.pending}
                            pagination={undefined}
                            cards={elements.data.map((variant: Variant) => (
                                <TeaserCard
                                    key={`article-page-variant-${variant.id}`}
                                    variant={variant}
                                    inverted={inverted}
                                />
                            ))}
                            onLoadNewItems={(data) => {
                                fetchElementsByIds(data.pageOrOffset, data.size);
                            }}
                            type={BasicCardVariants.BASIC_CARD_SMALL}
                        />
                    </div>
                );
            } else {
                return (
                    <div>
                        <Teaser
                            headline={headline.headlineContent}
                            hideTeaserButtons={false}
                            inverted={inverted}
                            maxCards={elements.maxNumber}
                            headlineElement={headline.element}
                            headlineVariant={mapStrapiHeadline(headline.style)}
                            withNumbers={true}
                            pending={elements.pending}
                            pagination={undefined}
                            cards={elements.data.map((instance: DetailedInstance) => (
                                <TeaserCard
                                    key={`article-page-variant-${instance.id}`}
                                    instance={instance}
                                    inverted={inverted}
                                />
                            ))}
                            onLoadNewItems={(data) => {
                                fetchElementsByIds(data.pageOrOffset, data.size);
                            }}
                            type={BasicCardVariants.BASIC_CARD_SMALL}
                        />
                    </div>
                );
            }
        } else {
            return <div/>;
        }
    };
