import { useQuery, UseQueryResult } from 'react-query';

import { backend } from '../../utils/_config/axios';
import { StrapiFaqItem } from '../models/strapi/strapi-faq-item';

export const useResolveFaqItems = (
    fa_qs: StrapiFaqItem[]
): UseQueryResult<StrapiFaqItem[], unknown> =>
    useQuery(['resolve-faq-items', fa_qs], async () => {
        const res = await Promise.all(
            fa_qs?.map(async (faqIdItem) => {
                const { id } = (faqIdItem as unknown) as { id: number };

                return (await backend().get<StrapiFaqItem>(`api/strapi/fa-qs/${id}`)).data;
            })
        );

        return res;
    });
