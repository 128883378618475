import parse from 'html-react-parser';
import React from 'react';

import { HorizontalPosition } from '../../../utils/_enums/horizontal-position.enum';
import HeadImageSection from '../head-image-section/head-image-section';
import { Typography } from '../typography/typography';
import styles from './head-image-with-text-section.module.scss';

export interface HeadImageWithTextSectionProps {
    id?: string;
    imageUrl?: string;
    alt?: string;
    caption: string;
    text: string;
    imagePosition: HorizontalPosition;
    inverted?: boolean;
}

const HeadImageWithTextSection = (
    props: HeadImageWithTextSectionProps
): JSX.Element => {
    const position =
        props.imagePosition === HorizontalPosition.LEFT ? 'left' : 'right';

    return (
        <div
            id={props?.id}
            className={`${styles['head-image-with-text-wrapper']} ${
                styles[`${position}`]
            }`}
        >
            <div className={styles['image-wrapper']}>
                <HeadImageSection
                    imageUrl={props?.imageUrl ?? ''}
                    alt={props?.alt}
                    caption={props?.caption}
                    inverted={props?.inverted}
                />
            </div>

            <Typography
                inverted={props?.inverted}
                className={styles['text-container']}
            >
                {parse(props?.text)}
            </Typography>
        </div>
    );
};

export default HeadImageWithTextSection;
