import React from 'react';
import { StrapiVideoPlatforms } from '../../../utils/_enums/strapi-video-platforms.enum';

import styles from './video-component.module.scss';

export interface VideoComponentProps {
    videoId: string;
    platform: StrapiVideoPlatforms;
    controls?: boolean;
    autoplay?: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = (props: VideoComponentProps): JSX.Element => {
    
    let embedCode;
    if (props.platform === StrapiVideoPlatforms.YOUTUBE) {
        embedCode = <iframe
            src={`https://www.youtube.com/embed/${props?.videoId}${props?.controls ? '?':'?controls=0'}${props?.autoplay ? '&autoplay=1&mute=1':''}`}
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>;
    } else {
        embedCode = 
            <>  
                <iframe
                    src={`https://player.vimeo.com/video/${props?.videoId}${props?.controls ? '?controls=1':'?controls=0'}${props?.autoplay || !props?.controls ? '&autoplay=1&muted=1':''}`}
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture"
                    allowFullScreen
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </>
    }
            
    return (
        <div className={`${styles['video-component-wrapper']}`} >
            {embedCode}
        </div>
    );
};

export default VideoComponent;
               
