import React from 'react';

import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../typography/typography';
import styles from './number-text-teaser.module.scss';
export interface NumberTextTeaserProps {
    id?: string;
    largeText?: string;
    subText?: string;
}

const NumberTextTeaser: React.FC<NumberTextTeaserProps> = (props) => {
    return (
        <div id={props?.id} className={styles['number-text-teaser']}>
            <Typography
                element={'span'}
                variant={TypographyVariantsEnum.HEADING1}
            >
                {props.largeText}
            </Typography>
            <Typography
                element={'span'}
                variant={TypographyVariantsEnum.HEADING4_BOLD}
            >
                {props.subText}
            </Typography>
        </div>
    );
};

export default NumberTextTeaser;
